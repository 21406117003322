<template>
  <router-view />
</template>

<script>
import { useMap } from '@/compositions/map';
import { onMounted, watch } from '@vue/composition-api';
import { onBeforeRouteLeave } from '@/compositions/router';
import { useLandmarks } from '@/modules/landmarks/compositions/landmarks';
import { useGeozones } from '@/modules/geozones/compositions/geozones';

export default {
  name: 'Objects',

  setup() {
    const { landmarks, geozones } = useMap();

    const { list: landmarkList } = useLandmarks();
    const { list: geozoneList } = useGeozones();

    watch(
      () => landmarkList.value,
      value => {
        landmarks.clearLandmarks();
        landmarks.setLandmarks(value);
      }
    );

    watch(
      () => geozoneList.value,
      value => {
        geozones.clearGeozones();
        geozones.setGeozones(value);
      }
    );

    onMounted(() => {
      landmarks.setLandmarks(landmarkList.value);
      geozones.setGeozones(geozoneList.value);
    });

    onBeforeRouteLeave((from, to, next) => {
      landmarks.clearLandmarks();
      geozones.clearGeozones();
      next();
    });
  }
};
</script>

<style></style>
